<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Columns
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          ></span
        >
      </div>
      
    </div>
    <form class="form">
      <div class="card-body">
        
	

		<div id="app">
		
		<div class="repeater" v-if="repeaterData">
      {{ repeaterData }}
				<table class="table table-responsive">
					<thead>
					<tr>
					  <th></th>
					   <th width="150px">Title</th>
					  <th>Width</th>
            <th>Length</th>
						<th>Align</th>
					  <th>Field</th>
					  <th>Type</th>
					  <th></th>
					</tr>
				  </thead>
					<tbody>
						
						<tr :value="repeaterData" v-for="(col, index) in repeaterData" :key="index">
							<td><span class="input-group-btn-vertical">
							<a style="cursor: pointer;" v-on:click="positionUp(index)" class="link">Up</a><br/>
							<a style="cursor: pointer;" v-on:click="positionDown(index)" class="">Down</a>
							</span>
							</td>
							
							<td><input class="form-control form-control-solid" type="text" v-model="col.title"></td>
							<td><input class="form-control form-control-solid" type="text" v-model="col.width"></td>
							<td><input class="form-control form-control-solid" type="text" v-model="col.length"></td>
							<td>
							
							  
							  <el-select v-model="col.align" :placeholder="col.align">
									<el-option
									  v-for="item in options_col_align"
									  :key="item.value"
									  :label="item.text"
									  :value="item.value">
									</el-option>
								  </el-select>
							  
							</td>
							<td>
							
							    <el-select v-model="col.field" :placeholder="col.field">
									<el-option
									  v-for="item in options_col_field"
									  :key="item.value"
									  :label="item.text"
									  :value="item.value">
									</el-option>
								  </el-select>

                  <el-input v-model="col.field" style="width: 240px" :placeholder="col.field" />
							  
							  
							</td>

							<td>
							
							  
							  <el-select v-model="col.type" :placeholder="col.type">
									<el-option
									  v-for="item in options_col_type"
									  :key="item.value"
									  :label="item.text"
									  :value="item.value">
									</el-option>
								  </el-select>
							  
							</td>
							
							<td>
						
							<div class="modal fade" tabindex="-1" :id="'modalColumnsOptions_'+index">
							  <div class="modal-dialog">
								<div class="modal-content">
								  <div class="modal-header">
									<h5 class="modal-title">Extra options for {{col.field}}</h5>

									<!--begin::Close-->
									<div
									  class="btn btn-icon btn-sm btn-active-light-primary ms-2"
									  data-bs-dismiss="modal"
									  aria-label="Close"
									>
									  <span class="svg-icon svg-icon-2x"></span>
									</div>
									<!--end::Close-->
								  </div>

								  <div class="modal-body">

                    <div v-if="col.type === 'editButton'">
                        Method: (modal or route)
                        <input :ref="'option_link_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, $event.target.value, 'method')" :value="checkOptions(col, 'method')">
                     
                      Method target: (modal id or path)
                        <input :ref="'option_link_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, $event.target.value, 'methodTarget')" :value="checkOptions(col, 'methodTarget')">
                     
                        Custom:
                        <el-switch :ref="'option_link_'+index" @change="change_option(index, true, 'custom')" :value="checkOptions(col, 'custom')" />

                        <!-- <input :ref="'option_link_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, $event.target.value, 'custom')" :value="checkOptions(col, 'custom')"> -->
                     
                      </div>

									<div v-if="col.type == 'pdfView'">
									Icon
									<input :ref="'option_link_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, $event.target.value, 'name')" :value="checkOptions(col, 'name')">
                  <br/>Tooltip
                  <input :ref="'option_link_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, $event.target.value, 'tooltip')" :value="checkOptions(col, 'tooltip')">
				        	<br/>
									</div>
									
									<div v-if="col.type == 'maxCompare'">
									Fieldname to compare
                  <input :ref="'option_link_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, $event.target.value, 'fieldName')" :value="checkOptions(col, 'fieldName')">
				        	<br/>
									</div>

                  <div v-if="col.type == 'internalPageButton'">
									Page url
                  <input :ref="'option_link_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, value, 'pageURL')" :value="checkOptions(col, 'pageURL')">
				        	<br/>
									</div>

                  <div v-if="col.type == 'dropdownMenu'">
									Dropdown Menu
                  <input :ref="'option_link_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, $event.target.value, 'iconLabel')" :value="checkOptions(col, 'iconLabel')">
				        	<br/>
                  <input :ref="'option_link_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, $event.target.value, 'iconName')" :value="checkOptions(col, 'iconName')">
				        	<br/>
                  <input :ref="'option_link_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, $event.target.value, 'items')" :value="checkOptions(col, 'items')">
				        	<br/>
									</div>

                                    <div v-if="col.type == 'changeInlineBoolean'">
									Auto Dispatch
                   <el-switch :ref="'option_link_'+index" @change="change_option(index, true, 'autoDispatch')" :value="checkOptions(col, 'autoDispatch')" />
                   
                   <!-- <input :ref="'option_link_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, $event.target.value, 'autoDispatch')" :value="checkOptions(col, 'pageURL')"> -->
				        	<br/>
									</div>
									
									<div v-if="col.type == 'simpleLink' || col.type == 'simpleLinkLanguage' || col.type == 'status' || col.type == 'countryFlag' || col.type == 'modalButton'">
									Selection
									<br/>
								   <el-select style="width:100%" v-if="col.options" v-model="col.options['selection']" :placeholder="col.options['selection']">
									<el-option
									  v-for="item in options_selection"
									  :key="item.value"
									  :label="item.text"
									  :value="item.value">
									</el-option>
								  </el-select>
								  <br/>
								  <input :ref="'option_selection_'+index" class="form-control form-control-solid" @change="change_option(index, $event.target.value, 'selection')" type="hidden" :value="checkOptions(col, 'selection')">
									<br/>
									</div>
									
									<template v-if="col.options">
										<template v-if="col.options['selection']">
											<template v-if="col.options['selection'] == 'route'">
											API Endpoint
											<input :ref="'option_selection_'+index" class="form-control form-control-solid" @change="change_option(index, $event.target.value, 'route')" type="text" :value="checkOptions(col, 'route')">
											<br/>
											</template>
										</template>
									</template>
									
									
									<template v-if="col.options">
										<template v-if="col.options['selection']">
											<template v-if="col.options['selection'] == 'route'">
											Field
											<input :ref="'option_link_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, $event.target.value, 'name')" :value="checkOptions(col, 'name')">
											<br/>
											</template>
										</template>
									</template>
									
									<template v-if="col.options">
										<template v-if="col.options['selection']">
											<template v-if="col.options['selection'] == 'route' || col.options['selection'] == 'settings' || col.options['selection'] == 'modal'">
											Key
											<input :ref="'option_field_'+index" class="form-control form-control-solid" @change="change_option(index, $event.target.value, 'link')" type="text" :value="checkOptions(col, 'link')">
											</template>
										</template>
									</template>
								
								  </div>

								  <div class="modal-footer">
									<button
									  type="button"
									  class="btn btn-light"
									  data-bs-dismiss="modal"
									>
									  Close
									</button>
									<button v-on:click="options_continue(index)" type="button" class="btn btn-primary" data-bs-dismiss="modal">
										Continue
									</button>
								  </div>
								</div>
							  </div>
							</div>
							
							<button
							  v-on:click="select_option(index, '', 'selection')"
							  v-if="col.type == 'maxCompare' || col.type == 'internalPageButton' || col.type == 'dropdownMenu' || col.type == 'changeInlineBoolean' || col.type == 'pdfView' || col.type == 'simpleLink' || col.type == 'simpleLinkLanguage' || col.type == 'status' || col.type == 'countryFlag' || col.type == 'editButton'"
							  type="button"
							  class="btn btn-primary btn-sm"
							  data-bs-toggle="modal"
							  :data-bs-target="'#modalColumnsOptions_'+index"
							>
							 <i class="fa fa-cog"></i>
							</button>
					
							<input class="form-control form-control-solid" type="hidden" :value="JSON.stringify(col.options)" ></td>
							
							
							<td><a class="btn btn-danger btn-sm" v-on:click="remove($event, index)"><i class="fa fa-trash"></i></a></td>
						</tr>
						<tr><td colspan="6"></td><td><button
							  type="button"
							  class="btn btn-sm btn-secondary"
							  v-on:click="generate"
							>
							 <i class="fa fa-plus"></i>
							</button></td></tr>
					</tbody>
				</table>
				
			</div>
			
	
              <input 
                class="form-control form-control-lg form-control-solid"
                ref="Columns"
                type="hidden"
				:value="JSON.stringify(repeaterData)"
              />
			  

			  
      </div>

      </div>
	  <div class="card-footer d-flex justify-content-end py-6 px-9">
	  <span :class="spinner"></span>
      <button v-if="save_button"
        type="button"
        class="btn btn-primary"
        @click="saveTrigger()"
        ref="kt_save_changes"
      >
      Save Changes
      </button>
    </div>
    </form>
  </div>
</template>

<script>
	import axios from 'axios'
	
    export default {
       data(){
           return {
			  repeaterData: null,
			  options_selection: [
				  { value: null, text: '' },
				  { value: 'route', text: 'Data from API' },
				  { value: 'settings', text: 'Data from settings' },
				  { value: 'modal', text: 'Modal ID' }
				],
			  options_col_type: [
				  { value: null, text: '' },
				  { value: 'text', text: 'text' },
          { value: 'price', text: 'price' },
          { value: 'textShort', text: 'textShort' },
				  { value: 'status', text: 'status' },
				  //{ value: 'statusReturn', text: 'statusReturn' },
					{ value: 'statusBoolean', text: 'statusBoolean' },
          { value: 'statusBooleanInline', text: 'statusBooleanInline'},
				  { value: 'simpleLink', text: 'simpleLink' },
					{ value: 'simpleLinkLanguage', text: 'simpleLinkLanguage' },
				  { value: 'countryFlag', text: 'countryFlag' },
				  { value: 'smallImage', text: 'smallImage' },
					{ value: 'iconSvg', text: 'iconSvg' },
					{ value: 'thumbnail', text: 'thumbnail' },
				  { value: 'editButton', text: 'editButton' },
				  { value: 'modalButton', text: 'modalButton' },
          { value: 'internalPageButton', text: 'internalPageButton' },
          { value: 'dropdownMenu', text: 'dropdownMenu' },
          { value: 'externalPageButton', text: 'externalPageButton' },
				  { value: 'dateTime', text: 'dateTime' },
				  { value: 'textTranslation', text: 'textTranslation' },
          { value: 'textLabel', text: 'textLabel' },
				  { value: 'inputText', text: 'inputText' },
				  { value: 'pdfView', text: 'pdfView' },
					{ value: 'badgeOptions', text: 'badgeOptions' },
          { value: 'invoiceEmail', text: 'invoiceEmail' },
          { value: 'maxCompare', text: 'maxCompare'},
          { value: 'changeInlineBoolean', text: 'changeInlineBoolean'}
				],
				options_col_align: [
				  { value: '', text: 'default' },
					{ value: 'left', text: 'left' },
					{ value: 'center', text: 'center' },
					{ value: 'right', text: 'right' }
				],
			  options_col_field: [],
              spinner: '',
              save_button: true,
              uid: this.$route.params.uid,
			  ajaxData: {"component":{"columns":null}}
           }
       },
		methods:{
			async saveChanges () {
			  this.spinner = 'spinner spinner-sm spinner-success spinner-right'
			  this.save_button = false
			  axios({
				method: 'put',
				url: 'https://keetels-api.eliteswitch.com/api/component/'+this.uid,
				data: {
					columns: this.$refs.Columns.value
				  }
			  }).then((response) => {
				this.ajaxData = response.data.result
				this.spinner = ''
				this.save_button = true
			  }, (error) => {
				console.log(error)
			  })
			},
			async refreshTable () {
			  this.spinner = 'spinner spinner-sm spinner-success spinner-right'
			  this.repeaterData = null
			  this.save_button = false
			  axios({
				method: 'get',
				url: 'https://keetels-api.eliteswitch.com/api/component/'+this.uid
			  }).then((response) => {
			    this.repeaterData = JSON.parse(response.data.result.item.columns)
				if(response.data.result.item.columns == null){
					this.repeaterData = []
				}
				this.ajaxData = response.data.result
				this.spinner = ''
				this.save_button = true
			  }, (error) => {
				console.log(error)
			  })
			},
			saveTrigger() {
			  this.ajaxData.item.columns = this.$refs.Columns.value
			  this.saveChanges()
			},
			positionUp: function(val){
				if( val == 0){
					return false;
				}
				const newArray = this.repeaterData;
				const new_index = (val - 1);
				const current_index = val;
			
				if (new_index >= newArray.length) {
					let k = new_index - newArray.length + 1;
					while (k--) {
						newArray.push(undefined);
					}
				}
				newArray.splice(new_index, 0, newArray.splice(current_index, 1)[0]);
			},
			positionDown: function(val){
				if( val == (this.repeaterData.length - 1)){
					return false;
				}
				// alert(this.repeaterData.length+' - '+val)
				const newArray = this.repeaterData;
				const new_index = (val + 1);
				const current_index = val;
			
				if (new_index >= newArray.length) {
					let k = new_index - newArray.length + 1;
					while (k--) {
						newArray.push(undefined);
					}
				}
				newArray.splice(new_index, 0, newArray.splice(current_index, 1)[0]);
			},
			generate: function(){
				this.repeaterData.push({type:'', field:'', title: ''})
			},
			remove: function(e, index){
			// alert(index);
				this.repeaterData.splice(index, 1);
	
			},
			change_option(a, b, c){
				
				if(Object.prototype.hasOwnProperty.call(this.repeaterData[a],'options')){
					this.repeaterData[a].options[c] = b
					this.positionUp(0)
					// this.positionDown(0)
				} else{
					this.repeaterData[a].options = {}
					this.repeaterData[a].options[c] = b
					this.positionUp(0)
					// this.positionDown(0)
				}
			},
			options_continue(a){
				if(Object.prototype.hasOwnProperty.call(this.repeaterData[a],'options')){
					if(this.repeaterData[a].options['selection'] == null){
						this.repeaterData[a].options['link'] = ''
						this.repeaterData[a].options['field'] = ''
						this.positionUp(0)
					}
				}
			},
			select_option(a, b, c){
				if(!Object.prototype.hasOwnProperty.call(this.repeaterData[a],'options')){
					this.repeaterData[a].options = {}
					this.repeaterData[a].options[c] = b
					this.positionUp(0)
				}
			},
			checkOptions(obj, check){
        console.log(obj)
				if(Object.prototype.hasOwnProperty.call(obj, 'options')){
					if(Object.prototype.hasOwnProperty.call(obj.options, check)){
						return obj.options[check];
					}
				}
			}
		},
		watch: {
			ajaxData: function (val) {
				this.spinner = 'spinner spinner-sm spinner-success spinner-right'
				this.save_button = false
				axios({
					method: 'options',
					url: 'https://keetels-api.eliteswitch.com/api/'+val.item.endpoint
				  }).then((response) => {
					if(response.data.result){
					 for (const field in response.data.result.data.fields) {
						this.options_col_field.push({value: field, text: field})
					 }
					}
					
					 this.spinner = ''
					 // if(response.data.result.length > 0){
						this.save_button = true
					 // }
					 

				  }, (error) => {
					console.log(error)
				  })
			
			},
		  },
		created: function(){
			this.repeaterData = [];
		},
		mounted () {
			this.refreshTable()
		  },
    }
	
</script>